# App Structure
App =
  Models: {}
  Collections: {}
  Views: {}

class App.Socket

  init: ->
    @io = io()

    # probably useless because the server gets a 'connection' hook
    @io.emit('join', App.I.id, App.I.attributes.id)

    # future improvement: move all of this in the corresponding views
    # maybe better: move all in the main view (ex: home)

    @io.on 'message', (message) ->
      console.log "io|message| #{JSON.stringify(message)}"
      sender = App.Users.findWhere(id: message.user_id)
      unless sender
        sender = App.Pages.findWhere(id: message.destination_id)
      message = new App.Models.Message message
      App.Messages.push(message)
      if sender and sender.messages_collection
        sender.messages_collection.push message

    @io.on 'user:add', (msg) ->
      console.log "io|user:add| #{JSON.stringify(msg)}"
      user = App.Users.findWhere(id: msg.id)
      unless user
        msg.confirmed = true
        App.Users.push new App.Models.User(msg)
      else
        user.set confirmed: true

    @io.on 'pageLink:add', (page) ->
      console.log "io|pageLink:add| #{JSON.stringify(page)}"
      page = new App.Models.Page page
      App.Pages.push page

    @io.on 'pageLink:delete', (page) ->
      console.log "io|pageLink:delete| #{JSON.stringify(page)}"
      App.Pages.remove page.id

App.Io = new App.Socket()

# Conversion helpers
to_b64    = (bin) ->
  sjcl.codec.base64.fromBits(bin).replace(/\//g,'_').replace(/\+/g,'-')

from_b64  = (b64) ->
  sjcl.codec.base64.toBits(b64.replace(/\_/g,'/').replace(/\-/g,'+'))

to_hex    = sjcl.codec.hex.fromBits
from_hex  = sjcl.codec.hex.toBits

to_utf8   = sjcl.codec.utf8String.fromBits
from_utf8 = sjcl.codec.utf8String.toBits

# Crypto helpers
App.S =
  cipher: sjcl.cipher.aes
  mode: sjcl.mode.ccm
  curve: sjcl.ecc.curves.c384

  x00: sjcl.codec.hex.toBits "0x00000000000000000000000000000000"
  x01: sjcl.codec.hex.toBits "0x00000000000000000000000000000001"
  x02: sjcl.codec.hex.toBits "0x00000000000000000000000000000002"
  x03: sjcl.codec.hex.toBits "0x00000000000000000000000000000003"

  encrypt: (key, data, iv) -> # (bin, bin, bin) -> bin
    cipher = new App.S.cipher(key)
    App.S.mode.encrypt(cipher, data, iv)

  decrypt: (key, data, iv) -> # (bin, bin, bin) -> bin
    cipher = new App.S.cipher(key)
    App.S.mode.decrypt(cipher, data, iv)

  hide: (key, data) -> # (bin, bin) -> b64
    iv = sjcl.random.randomWords(4)
    to_b64 sjcl.bitArray.concat(iv, App.S.encrypt(key, data, iv))

  bare: (key, data) -> # (bin, b64) -> bin
    data = from_b64(data)
    iv = sjcl.bitArray.bitSlice(data, 0, 128)
    hidden_data = sjcl.bitArray.bitSlice(data, 128)
    App.S.decrypt(key, hidden_data, iv)

  hide_text: (key, text) -> # (bin, utf8) -> b64
    App.S.hide(key, from_utf8(text))

  bare_text: (key, data) -> # (bin, b64) -> utf8
    to_utf8(App.S.bare(key, data))

  hide_seckey: (key, seckey) -> # (bin, sec) -> b64
    App.S.hide(key, seckey.toBits())

  bare_seckey: (key, data) -> # (bin, b64) -> sec
    sjcl.bn.fromBits App.S.bare(key, data)

FileHasher = (file, callback) ->
  BLOCKSIZE = 2048

  i = 0
  j = Math.min(BLOCKSIZE, file.size)
  reader = new FileReader()
  sha = new sjcl.hash.sha256()

  hash_slice = (i, j) -> reader.readAsArrayBuffer file.slice(i, j)

  reader.onloadend = (e) ->
    array = new Uint8Array @result
    bitArray = sjcl.codec.bytes.toBits(array)
    sha.update(bitArray)

    if i isnt file.size
      i = j
      j = Math.min(i + BLOCKSIZE, file.size)
      setTimeout (-> hash_slice i, j), 0
    else
      callback sha.finalize()

  hash_slice(i, j)

class App.Views.home extends Backbone.View

  el: 'body'

  events:
    'click #addContact': 'contactModal'
    'click #addChannel': 'channelModal'
    'click #createChannelButton': 'createChannel'
    'click #logout': 'logout'
    'keyup #addUserInput': 'findUser'

  contactModal: =>
    $("#contactModal").modal("show")

  channelModal: =>
    $("#channelModal").modal("show")

  createChannel: =>
    key  = sjcl.random.randomWords(8)
    name = $("#createChannelInput").val()
    page = new App.Models.Page(
      name: name
      key: key
      hidden_key: App.S.hide(App.I.get('mainkey'), key)
    ) # note: hidden_key should be computed in initializator
    page.save()
    page.on 'error', => alert("Can't save...")
    page.on 'sync', =>
      $("#channelModal").modal("hide")
      App.Pages.add(page)
      @render()

  findUser: =>
    pseudo = $("#addUserInput").val()
    return unless pseudo
    $.ajax url: '/user/' + pseudo, success: (users) =>
      console.log users
      App.SearchResults.reset(users)
      $('#userSearchList').empty()
      App.SearchResults.each (user) =>
        user_result_view = new App.Views.searchResult(model: user)
        user_result_view.render()
        $('#userSearchList').append(user_result_view.el)

  logout: =>
    App.Router.logout()

  render: =>
    template = Handlebars.compile $("#homeTemplate").html()
    $("#content").html template(I: App.I.get('pseudo'))
    $("#messageInput").autosize()

    # fix events not binded on modals
    #$("#createChannelButton").click(@createChannel)
    #$("#addUserInput").keyup(@findUser)

    App.Views.UserList = new App.Views.userList
      el: $("#userList")
    App.Views.UserList.render()

    App.Views.PageList = new App.Views.pageList
      el: $("#pageList")
      collection: App.Pages
    App.Views.PageList.render()

    App.Views.PageList = new App.Views.rightMenu
      el: $("#rightMenu")
      model: App.I
    App.Views.PageList.render()

    @

class App.Views.Login extends Backbone.View
  render: =>
    @$el.html $("#loginTemplate").html()
    @

  events:
    'click #signin': 'signin'
    'click #signup': 'signup'

  init_user: (pseudo, password) =>
    App.I = new App.Models.I
      pseudo:   pseudo
      password: sjcl.hash.sha256.hash(password)
    App.I.compute_secrets()

  store_login: =>
    localStorage.setItem "pseudo", App.I.get("pseudo")
    localStorage.setItem "local_secret", to_b64(App.I.get("local_secret"))
    localStorage.setItem "remote_secret", App.I.get("remote_secret")

  signup: =>
    $("#signupForm").removeClass("error").addClass("loading")

    pseudo   = $("#signupPseudoInput").val()
    password = $("#signupPasswordInput").val()
    @init_user(pseudo, password)

    # temporary
    password_confirm = $("#signupPasswordConfirmInput").val()
    if password isnt password_confirm
      alert("password and confirmation don't match")
      return

    App.I.create_ecdh().create_mainkey().hide_ecdh().hide_mainkey()
    App.I.isNew = -> true
    App.I
      .on 'error', =>
        $("#signupForm").removeClass("loading").addClass("error")
      .on 'sync', =>
        App.Io.init()
        App.Router.show("home")
      .save()

  signin: =>
    $("#signinForm").removeClass("error").addClass("loading")
    pseudo   = $("#signinPseudoInput").val()
    password = $("#signinPasswordInput").val()
    @init_user(pseudo, password)

    App.I.login
      success: (res) =>
        @store_login() if $("#rememberMe").prop("checked")
        App.I.load_data(res)
        App.Io.init()
        App.Router.show("home")
      error: =>
        $("#signinForm").removeClass("loading").addClass("error")

class App.Views.messageList extends Backbone.View

  initialize: =>
    @collection.fetch(remove: false, data: limit: @collection.limit)
    @listenTo(@collection, 'add', @render)
    
  events:
    'click #load_older_messages': 'load_older_messages'

  load_older_messages: =>
    @collection.fetch(remove: false, data: limit: @collection.limit, offset: @collection.length)

  process_collection: =>
    messages = @collection.sort().map((e) -> e.attributes)
    for message in messages
      user = if message.user_id is App.I.get('id')
        App.I
      else
        App.Users.findWhere(id: message.user_id)
      destination = if message.destination_id is App.I.get('id')
        App.I
      else if message.destination_type == "user"
        App.Users.findWhere(id: message.destination_id)
      else
        App.Pages.findWhere(id: message.destination_id)
      message.source = user.attributes
      message.destination = destination.attributes
      message.createdAt = (new Date(message.createdAt)).toLocaleString()
    messages

  render: =>
    template = Handlebars.compile $("#messageListTemplate").html()
    @$el.html template(messages: @process_collection())
    @

class App.Views.pageLinkList extends Backbone.View

  initialize: =>
    @listenTo App.PageLinks, 'add', @render
    @listenTo App.PageLinks, 'remove', @render

  page_users: =>
    users = []
    App.Users.each (user) =>
      tmp = user.pick('id', 'pseudo')
      if @model.get('user_id') is user.get('id')
        tmp.creator = true
      link = App.PageLinks.findWhere
        page_id: @model.get('id'), user_id: user.get('id')
      if link then tmp.auth = true
      users.push(tmp)
    users

  render: =>
    template = Handlebars.compile $("#pageLinkListTemplate").html()
    @$el.html template(users: @page_users())
    @

  events:
    'click .create': 'create'
    'click .delete': 'delete'

  create: (e) =>
    page_id = @model.get('id')
    user_id = $(e.target).data('id')

    user = App.Users.findWhere(id: user_id)
    page = App.Pages.findWhere(id: page_id)

    hidden_key = App.S.hide user.get('shared'), page.get('key')

    App.PageLinks.create(
      page_id: page_id
      user_id: user_id
      hidden_key: hidden_key
    )
    false

  delete: (e) =>
    App.PageLinks.findWhere(
      page_id: @model.get('id')
      user_id: $(e.target).data('id')
    ).destroy()
    false

class App.Views.page extends Backbone.View
  render: =>
    data = @model.pick 'id', 'name'
    data.user = @model.getUser().pick('id', 'pseudo')
    template = Handlebars.compile $("#pageTemplate").html()
    @$el.html template(data)
    @

class App.Views.pageList extends Backbone.View

  initialize: =>
    @listenTo(App.Pages, 'change', @render)

  add_one: (model) =>
    view = new App.Views.page(model: model)
    $("#pageList").append(view.render().el)

  add_all: =>
    App.Pages.each(@add_one)

  render: =>
    $("#pageList").empty()
    @add_all()

class App.Views.pageTalk extends Backbone.View

  events:
    'click #messageButton': 'send_message'

  page_users: =>
    _.map App.Users.toJSON(), (user) ->
      link = App.pageLinks.where
        page_id: @model.get('id')
        user_id: user.id
      user.auth = true if link
      user

  render: =>
    @messageList = new App.Views.messageList
      el: $("#messageList")
      collection: @model.messages_collection
    @pageLinkList = new App.Views.pageLinkList
      el: $("#pageLinkList")
      model: @model

    @messageList.render()
    @pageLinkList.render()

  send_message: =>
    content = $("#messageInput").val()
    hidden_content = App.S.hide_text(@model.get('key'), content)

    message = new App.Models.Message
      destination_type: "page"
      destination_id: @model.get('id')
      hidden_content: hidden_content
      content: content

    message
      .on 'error', => alert "Sending error"
      .on 'sync', =>
        App.Messages.add(message)
        @messageList.collection.push(message)
        @messageList.render()
        $("#messageInput").val("")
      .save()

class App.Views.rightMenu extends Backbone.View

  events:
    'click #confirmContact': 'confirm'

  initialize: =>
    App.Router.on 'route', (route, args) =>
      @model = App.Users.findWhere(id: args[0])
      return unless @model
      @render()

  render: =>
    template = Handlebars.compile $("#rightMenuTemplate").html()
    @$el.html template(user: @model.attributes)
    @

  confirm: =>
    $.ajax
      url: "/user/#{@model.get('id')}/add"
      success: (res) =>
        @model.set added: true, confirmed: true
        @render()
      error: =>
        alert 'Error while accepting the request.'

class App.Views.userList extends Backbone.View

  initialize: =>
    @listenTo(App.Users, 'add remove change', @render)

  add_one: (user) =>
    view = new App.Views.user(model: user)
    $("#userList").append(view.render().el)

  add_all: =>
    App.Users.each(@add_one)

  render: =>
    $("#userList").empty()
    #@$el.html Handlebars.compile($("#userListTemplate").html())()
    @add_all()

class App.Views.user extends Backbone.View

  events:
    'click .block': 'block'

  block: (e) =>
    e.preventDefault()
    $.ajax url: "/user/#{@model.get('id')}/block"
    , success: (res) =>
      @model.set blocked: true
    , error: =>
      alert 'Error while blocking the user.'

  render: =>
    template = Handlebars.compile $("#userTemplate").html()
    @$el.html template(@model.toJSON())
    @

class App.Views.userRequestList extends Backbone.View

  initialize: =>
    @listenTo(App.Users, 'add remove change', @render)

  render_list: =>
    @$('#requests_list').empty()
    _(App.Users.reject((u) -> u.get('added') or u.get('blocked'))).each (user) =>
      user_request_view = new App.Views.userRequest(model: user)
      user_request_view.render()
      @$('#requests_list').append(user_request_view.el)

  render: =>
    template = Handlebars.compile $("#userRequestListTemplate").html()
    @$el.html template()
    @render_list()

class App.Views.userRequest extends Backbone.View

  events:
    'click .accept': 'accept_request'
    'click .block':  'decline_request'

  accept_request: (e) =>
    e.preventDefault()
    $.ajax url: '/user/' + @model.get('id') + '/add', success: (res) =>
      @model.set added: true, blocked: false
    , error: =>
      alert 'Error while accepting the request.'

  decline_request: (e) =>
    e.preventDefault()
    $.ajax url: '/user/' + @model.get('id') + '/block', success: (res) =>
      @model.set blocked: true
    , error: =>
      alert 'Error while accepting the request.'

  render: =>
    template = Handlebars.compile $("#userRequestTemplate").html()
    @$el.html template(@model.toJSON())
    @

class App.Views.userBlockList extends Backbone.View

  initialize: =>
    @listenTo(App.Users, 'add remove change', @render)

  render_list: =>
    @$('#blocks_list').empty()
    _(App.Users.where(blocked: true)).each (user) =>
      view = new App.Views.userBlocked(model: user)
      view.render()
      @$('#blocks_list').append(view.el)

  render: =>
    template = Handlebars.compile $("#userBlockListTemplate").html()
    @$el.html template()
    @render_list()

class App.Views.userBlocked extends Backbone.View

  events:
    'click .unblock': 'unblock'

  unblock: (e) =>
    e.preventDefault()
    $.ajax url: '/user/' + @model.get('id') + '/add', success: (res) =>
      @model.set added: true, blocked: false
    , error: =>
      alert 'Error while accepting the request.'

  render: =>
    template = Handlebars.compile $("#userBlockTemplate").html()
    @$el.html template(@model.toJSON())
    @

class App.Views.searchResult extends Backbone.View

  events:
    'click a': 'add_friend'

  add_friend: (e) =>
    e.preventDefault()
    $.ajax
      url: '/user/' + @model.get('id') + '/add'
      success: =>
        $("#addUserInput").val("")
        App.SearchResults.reset()
        App.Users.add(@model.set(added: true))
        $("#userSearchList").empty()
        $("#contactModal").modal("hide")
      error: =>
        alert 'Error while sending the request.'


  render: =>
    template = Handlebars.compile $("#searchResultTemplate").html()
    @$el.html template(@model.toJSON())
    @

class App.Views.userTalk extends Backbone.View

  events:
    'click #messageButton': 'send_message'

  send_message: =>
    content = $("#messageInput").val()
    hidden_content = @hide_message(content)

    message = new App.Models.Message
      destination_type: "user"
      destination_id: @model.get('id')
      hidden_content: hidden_content
      content: content

    message.save()
    message
      .on 'error', => alert "Sending error"
      .on 'sync', =>
        App.Messages.add(message)
        @messageList.collection.push(message)
        @messageList.render()
        $("#messageInput").val("")

  hide_message: (content) =>
    if @model.get('id') is App.I.get('id')
      App.S.hide_text(App.I.get('mainkey'), content)
    else
      App.S.hide_text(@model.get('shared'), content)

  render: =>
    @messageList = new App.Views.messageList
      el: $("#messageList")
      collection: @model.messages_collection
    @messageList.render()

class App.Models.Message extends Backbone.Model
  urlRoot: "/message"

  initialize: =>
    @on('add', =>
      @bare())

  toJSON: =>
    @omit('content')


  bare: =>
    key = null

    if @get('user_id') is App.I.get('id') and @get('destination_id') is App.I.get('id')
      key = App.I.get('mainkey')

    else if @get('destination_type') is 'user'
      user = if @get('user_id') isnt App.I.get('id')
        App.Users.findWhere(id: @get('user_id'))
      else
        App.Users.findWhere(id: @get('destination_id'))
      key = user.get('shared')

    else if @get('destination_type') is 'page'
      page = App.Pages.findWhere(id: @get('destination_id'))
      key = page.get('key')
    else
      return console.log('The message type is invalid')

    @set content: App.S.bare_text(key, @get('hidden_content'))

class App.Models.Page extends Backbone.Model
  urlRoot: "/page"

  initialize: =>
    @messages_collection = App.Messages.where_page(@get('id'))
    @on 'add', =>
      @bare()

  toJSON: -> @pick("name", "hidden_key")

  bare: ->
    if @get('user_id') is App.I.get('id')
      @set key: App.S.bare(App.I.get('mainkey'), @get('hidden_key'))
    else
      user = App.Users.findWhere(id: @get('user_id'))
      @set key: App.S.bare(user.get('shared'), @get('hidden_key'))

  getUser: ->
    if @get('user_id') is App.I.get('id')
      App.I
    else
      App.Users.findWhere(id: tmp.user_id)

class App.Models.PageLink extends Backbone.Model
  urlRoot: "/pageLink"
class App.Models.User extends Backbone.Model
  urlRoot: "/user"

  initialize: =>
    @on 'add', =>
      @messages_collection = App.Messages.where_user(@get('id'))
      @shared()

  idAttribute: "pseudo"

  shared: ->
    public_point = App.S.curve.fromBits(from_b64(@get('pubkey')))
    shared_point = public_point.mult(App.I.get('seckey'))
    @set shared: sjcl.hash.sha256.hash(shared_point.toBits())

class App.Models.I extends App.Models.User

  toJSON: ->
    @pick "id", "pseudo", "pubkey", "remote_secret", "hidden_seckey", "hidden_mainkey"

  compute_secrets: ->
    key    = sjcl.misc.pbkdf2(@get('password'), @get('pseudo'))
    cipher = new sjcl.cipher.aes(key)
    @set 'local_secret', sjcl.bitArray.concat(cipher.encrypt(App.S.x00), cipher.encrypt(App.S.x01))
    @set 'remote_secret', to_b64 sjcl.bitArray.concat(cipher.encrypt(App.S.x02), cipher.encrypt(App.S.x03))

  create_ecdh: ->
    @set seckey: sjcl.bn.random(App.S.curve.r, 6)
    @set pubkey: to_b64(App.S.curve.G.mult(@get('seckey')).toBits())

  hide_ecdh: ->
    @set hidden_seckey: App.S.hide_seckey(@get('local_secret'), @get('seckey'))

  bare_ecdh: ->
    @set seckey: App.S.bare_seckey(@get('local_secret'), @get('hidden_seckey'))

  create_mainkey: ->
    @set mainkey: sjcl.random.randomWords(8)

  hide_mainkey: ->
    @set hidden_mainkey: App.S.hide(@get('local_secret'), @get('mainkey'))

  bare_mainkey: ->
    @set mainkey: App.S.bare(@get('local_secret'), @get('hidden_mainkey'))

  login: (options) ->
    unless options.success
      options.success = (->)
    unless options.error
      options.error = (->)
    $.ajax(
      url: "/login"
      type: "POST"
      contentType: 'application/json'
      dataType: 'json'
      data: JSON.stringify(@)
    ).success(options.success).error(options.error)

  load_data: (data) ->
    @set(data.I).bare_mainkey().bare_ecdh()
    # App.Users.push(App.I)
    App.Users.push(data.contacts)
    App.PageLinks.push(data.pageLinks)
    App.Pages.push(data.created_pages)
    App.Pages.push(data.accessible_pages)
    App.Messages.push(data.messages)

    App.Users.each (user) -> user.shared()
    App.Pages.each (page) -> page.bare()
    App.Messages.each (message) -> message.bare()

class App.Collections.FriendRequests extends Backbone.Collection
  model: App.Models.User

App.FriendRequests = new App.Collections.FriendRequests()

class App.Collections.Messages extends Backbone.Collection
  url: '/messages'

  model: App.Models.Message

  limit: 2

  comparator: (a, b) =>
    (new Date(a.get('createdAt'))) < (new Date(b.get('createdAt')))

  where_user: (id) ->
    messages = new App.Collections.Messages()
    messages.url = '/messages/user/' + id
    messages

  where_page: (id) ->
    messages = new App.Collections.Messages()
    messages.url = '/messages/page/' + id
    # messages.push @where
    #   destination_type: 'page'
    #   destination_id: id
    messages

App.Messages = new App.Collections.Messages()

class App.Collections.PageLinks extends Backbone.Collection
  model: App.Models.PageLink
  url: '/pageLinks'

App.PageLinks = new App.Collections.PageLinks()

class App.Collections.Pages extends Backbone.Collection
  model: App.Models.Page
  url: '/pages'

App.Pages = new App.Collections.Pages()

class App.Collections.SearchResults extends Backbone.Collection
  model: App.Models.User

App.SearchResults = new App.Collections.SearchResults()

class App.Collections.Users extends Backbone.Collection
  model: App.Models.User
  url: '/users'

App.Users = new App.Collections.Users()
App.FriendRequests = new App.Collections.Users()

class Router extends Backbone.Router

  routes:
    '': 'index'
    'home': 'home'
    'logout': 'logout'
    'user/:id': 'userTalk'
    'page/:id': 'pageTalk'

  show: (route) =>
    @navigate(route, {trigger: true, replace: true})

  logout: =>
    localStorage.clear()
    App.Messages.reset()
    App.Users.reset()
    App.FriendRequests.reset()
    App.Pages.reset()
    App.PageLinks.reset()
    @show('')

  auto_signin_tried: false

  auto_signin: (callback) ->
    return @index() if @auto_signin_tried
    @auto_signin_tried = true
    return @index() if localStorage.length < 3

    App.I = new App.Models.I
      pseudo: localStorage.getItem("pseudo")
      local_secret: from_b64(localStorage.getItem("local_secret"))
      remote_secret: localStorage.getItem("remote_secret")

    App.I.login
      success: (res) =>
        App.I.load_data(res)
        @view = new App.Views.home(el: $("body"))
        @view.render()
        callback()
      error: =>
        localStorage.clear()
        console.log("fail")
        @index()

  index: =>
    return @auto_signin(=> @show("home")) unless @auto_signin_tried

    @navigate("", {trigger: false, replace: true})
    @view = new App.Views.Login(el: $("#content"))
    @view.render()

  home: =>
    return @auto_signin(@home) unless App.I or @auto_signin_tried
    return @show("") unless App.I

    @view.undelegateEvents() if @view
    @view = new App.Views.home(el: $("body"))
    @view.render()

  userTalk: (id) =>
    unless App.I or @auto_signin_tried
      return @auto_signin(=> @userTalk(id))
    unless App.I
      return @show("")

    model = App.Users.findWhere(id: id)

    @view.undelegateEvents() if @view
    @view = new App.Views.userTalk(el: $("#content"), model: model)
    @view.render()

  pageTalk: (id) =>
    return @auto_signin(=> @pageTalk(id)) unless App.I or @auto_signin_tried
    return @show("") unless App.I

    model = App.Pages.findWhere(id: id)

    @view.undelegateEvents() if @view
    @view = new App.Views.pageTalk(el: $("#content"), model: model)
    @view.render()

App.Router = new Router

$ ->
  Backbone.history.start()
